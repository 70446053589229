$(function () {

    $('input').attr('autocomplete','off');

    $('[data-toggle="tooltip"]').tooltip();

    // $('#divPolicySummary').hide();
    
    $('#spanMember').hide();

    $('#datepicker').datepicker({
            uiLibrary: 'bootstrap4'
     });
     
     var txtAlert ='';
     $("#btnAssitDownload").on("click", function (event) {
        $('.modal-header').addClass('bg-msig-blue text-white');
        $('#ModalAssitDownload').modal('show');

    });
    
        
    $('#loginModal').on('shown.bs.modal', function () {
        $('#username').trigger('focus');
    });
    
    
    // show calecdar when click icon calendar
    $('.iconCalendar').click(function() {

        var arrIdName = $(this).attr(('id')).split('-');
        $("." + arrIdName[1]).focus();
    });
    
    
    //$("#formLogin").validate({
    //    rules: {
    //        username: {
    //            required: true,
    //            email: true
    //        },
    //        password: {
    //            required: true,
    //            minlength: 8
    //        }
    //    },
    //    messages: {
    //        password: {
    //            required: "Please provide a password",
    //            minlength: "Your password must be at least 8 characters long"
    //        },
    //        username: "Please enter a valid email address"
    //    },
    //    errorElement: "em",
    //    errorPlacement: function (error, element) {
    //        // Add the `help-block` class to the error element
    //        error.addClass("invalid-feedback");

    //        // Add `has-feedback` class to the parent div.form-group
    //        // in order to add icons to inputs
    //            //element.parents(".col-sm-5").addClass("has-feedback");
    //        element.addClass("is-invalid");

    //        if (element.prop("type") === "checkbox") {
    //            error.insertAfter(element.parent("label"));
    //        } else {
    //            error.insertAfter(element);
    //        }

    //        // Add the span element, if doesn't exists, and apply the icon classes to it.
    //        /*if (!element.next("span")[ 0 ]) {
    //            $("<span class='glyphicon glyphicon-remove form-control-feedback'></span>").insertAfter(element);
    //        }*/
    //    },
    //    success: function (label, element) {
    //        // Add the span element, if doesn't exists, and apply the icon classes to it.
    //        if (!$(element).next("span")[ 0 ]) {
    //            $("<span class='glyphicon glyphicon-ok form-control-feedback'></span>").insertAfter($(element));
    //        }
    //    },
    //    highlight: function (element, errorClass, validClass) {
          
    //        $(element).addClass("is-invalid").removeClass("is-valid");
    //        $(element).next("span").addClass("glyphicon-remove").removeClass("glyphicon-ok");
    //    },
    //    unhighlight: function (element, errorClass, validClass) {
    //        $(element).addClass("is-valid").removeClass("is-invalid");
    //        $(element).next("span").addClass("glyphicon-ok").removeClass("glyphicon-remove");
    //    },
    //    submitHandler: function () {
    //        //alert("submitted!");
    //        $('#loginModal').modal('hide');
    //        $('#logoLoadingModal').modal('show');
            
    //        var formName = '#formLogin';

    //        $.ajax({
    //            type: $(formName).attr('method'),
    //            url: $(formName).attr('action'),
    //            data: $(formName).serialize()
    //        })
    //                .done(function (data) {
                        
    //                    $('#logoLoadingModal').modal('hide');             
    //                    arrData = JSON.parse(data);
                        
    //                    if (arrData.status == 'success') {
    //                        $('#alertHeader').removeClass("bg-msig-red").addClass('bg-msig-blue');
    //                        $('#alertTitle').html(arrData.status);
    //                        $('#alertBody').html(arrData.message[0]);
    //                        $('#alertModal').modal('show');
                            
    //                        setTimeout(function () {
    //                            $('#alertModal').modal('hide');
    //                            window.location.reload();
    //                        }, 3000);
                            
    //                    } else {
    //                        $('#alertHeader').addClass('bg-msig-red text-white');
    //                        $('#alertTitle').html(arrData.message[0]);
    //                        $('#alertBody').html(arrData.message[1]);
    //                        $('#alertModal').modal('show');
    //                    }

    //                })
    //                .fail(function (data) {
    //                    //console.log('Fail : ' + data);
    //                    $('#logoLoadingModal').modal('hide');
    //                    /*$.each(data.responseJSON, function (key, value) {
    //                     var input = '#formRegister input[name=' + key + ']';
    //                     $(input + '+small').text(value);
    //                     $(input).parent().addClass('has-error');
    //                     });*/
    //                });

    //    }
    //});

});

// $.validator.setDefaults({
    
// });
