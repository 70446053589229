'use strict';

var options = typeof options === 'undefined' ? {} : options;

(function(document, $, _options) {
  var options = $.extend(
    {},
    {
      recaptchaSiteKey: null
    }, // default options
    _options || {}
  );

  function _findFloatableLabel() {
    // First we try looking for label as a sibling of the input and return it
    // if found.
    var label = $(this).siblings('.floatable').first();
    if (label.length > 0) {
      return label;
    }
    // Otherwise the input could be wrapped in an .input-group and the label
    // should be a sibling of the input group instead.
    return $(this).parents('.input-group').siblings('.floatable').first();
  }

  function _shouldMakeFloatable() {
    var value = $(this).val();
    // If no value is found, and the input is a dropdown, we should always make
    // its label float unless there's no default value.
    if (!$(this).val() && $(this).is('select') && $(this).val() === '') {
      return true;
    }
    return value ? true : false;
  }

  function enhanceFormLabels() {
    // Make labels float (if any) when focusing on inputs.
    var inputSelectors = '.form input, .form select, .form textarea';
    $(inputSelectors).on('focus', function() {
      // We only do this when the input is not readonly or disabled
      if (!$(this).prop('readonly') && !$(this).prop('disabled')) {
        _findFloatableLabel.call(this).addClass('floating');
      }
    }).on('blur', function() {
      // When focus is lost, if there's no data in the input, we change labels
      // to their previous states.
      if (!_shouldMakeFloatable.call(this)) {
        _findFloatableLabel.call(this).removeClass('floating');
      }
    }).on('change', function() {
      // When input's contents is changed, either via actual input or
      // programatically, we should also toggle the class based on the new
      // value.
      _findFloatableLabel.call(this).toggleClass('floating',
        _shouldMakeFloatable.call(this) ? true : false);
    }).each(function() {
      // On initial loading, make sure labels don't float if there's nothing in
      // an input.
      _findFloatableLabel.call(this).toggleClass('floating',
        _shouldMakeFloatable.call(this) ? true : false);
    });

    // For fields that have masks, need to handle hovering event as well.
    $('.form .has-mask').hover(function() {
      // For inputs that have masks, hovering should make the label float when
      // placeholder is set.
      _findFloatableLabel.call(this).addClass('floating');
    }, function() {
      // When hovering stops we simply unfloat the label if the input is not
      // currently focused, or the input has value.
      if (!$(this).is(':focus')) {
        _findFloatableLabel.call(this).toggleClass('floating',
          _shouldMakeFloatable.call(this) ? true : false);
      }
    });
  }

  function enhanceFormFields() {
    // If there are fields that fail validation, we shift the focus to the
    // first field that failed.
    var firstInvalidField = $('.form .is-invalid:first');
    firstInvalidField.focus();
    // Because there can be select fields that are hidden by the select2
    // plugin, focusing immediately might not have any effect. We need to wait
    // a bit and focus on the field again after it is enhanced by select2.
    // TODO This should be handled more properly instead of waiting using an
    // arbitrary number of ms.
    setTimeout(function() {
      if (firstInvalidField.hasClass('select2-hidden-accessible')) {
        firstInvalidField
          .next('.select2-container')
          .find('.select2-selection')
          .focus();
      }
    }, 100);
  }

  function enhanceFormSubmission() {
    function addGoogleRecaptcha() {
      // Google recaptcha is not available, skip.
      if (typeof grecaptcha === 'undefined') {
        return;
      }
      $('form .need-protect').on('click', function(e) {
        e.preventDefault();
        var $form = $(this).parents('form');
        var action = $(this).prop('name') || 'submit';
        grecaptcha.ready(function() {
          grecaptcha.execute(options.recaptchaSiteKey, {action: action}).then(function(token) {
            // Add hidden fields to the form to store the token before submission.
            $('<input/>', { type: 'hidden', name: 'action', value: action }).appendTo($form);
            $('<input/>', { type: 'hidden', name: 'g-recaptcha-response', value: token }).appendTo($form);
            // Submit the form as usual.
            $form.submit();
          });
        });
      });
    }

    addGoogleRecaptcha();
  }

  function initModals() {
  }

  $(document).ready(function() {
    enhanceFormLabels();
    enhanceFormFields();
    enhanceFormSubmission();
    initModals();
  });
})(document, jQuery, options);
